import Reporte from './reporte'
import Grafico from './grafico'
function Factura (showRender, appState) {
  const routes = {}

  routes['/kilogramos/grafico'] = () => <Grafico showRender={showRender} appState={appState} />
  routes['/kilogramos'] = () => <Reporte showRender={showRender} appState={appState} />

  return routes
}
export default Factura
