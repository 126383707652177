import { Fragment, useState, useEffect } from 'react'
import { DateTime } from 'luxon'
import axios, { CancelToken } from 'axios'
import parse from '../utils'

import { Col, Row } from 'reactstrap'
import DatePicker from '../componentes/DatePicker'
import State from '../componentes/States'
import Spacer from '../componentes/Spacer'

function Content () {
  const [dates, setDates] = useState()
  const [state, setState] = useState('')
  const [lavadas, setLavadas] = useState()
  const [reporte, setReporte] = useState()
  const [data, setData] = useState()
  const [mult, setMult] = useState(1)

  useEffect(() => {
    if (!dates || !lavadas || !reporte) return
    let cancel

    setState('loading')

    axios.get(`/api/get/quimicos/${lavadas}/${reporte}`, { params: { dates } },
      { cancelToken: new CancelToken(function executor (c) { cancel = c }) }).then(res => {
      setData(res.data)
      setState('')
    }).catch(err => {
      if (!axios.isCancel(err)) {
        console.log(err)
        setState('error')
      }
    })

    return () => {
      if (cancel) cancel()
    }
  }, [dates, lavadas, reporte])

  return (
    <>
      <Row className='mt-2 justify-content-center'><h2> Reporte Químicos</h2></Row>
      <Row>
        <DatePicker
          handleDate={strings => setDates(strings.map(e => DateTime.fromJSDate(e).toFormat('yyyy-MM-dd')))} handleTipo={tipo => {
            setLavadas(tipo.lavadas)
            setReporte(tipo.reporte)
          }} id='datepick'
        />
      </Row>
      <Row className='mt-4'>
        <Col>
          <h5>Seleccionar medida:</h5>
          <div className='form-check form-check-inline'>
            <input className='form-check-input' type='radio' name='medida' onChange={() => setMult(1)} defaultChecked />
            <label className='form-check-label'>Mililitros (mL)</label>
          </div>
          <div className='form-check form-check-inline'>
            <input className='form-check-input' type='radio' name='medida' onChange={() => setMult(0.001)} />
            <label className='form-check-label'>Litros (L)</label>
          </div>
          <div className='form-check form-check-inline'>
            <input className='form-check-input' type='radio' name='medida' onChange={() => setMult(0.000264172)} />
            <label className='form-check-label'>Galones (Gal)</label>
          </div>
        </Col>
      </Row>
      <Row>
        <Col md={12}>
          <State state={state} />
          <Reporte data={data} mult={parseFloat(mult)} />
        </Col>
      </Row>
    </>
  )
}

function Reporte (props) {
  const { data, mult } = props
  return (
    data ? data.reporte === 'total' ? <ReporteTotal {...data} mult={mult} /> : <ReporteFecha {...data} mult={mult} /> : null
  )
}

function ReporteTotal (props) {
  const results = props.results
  const lavs = Object.keys(results).sort()
  const progs = new Set()

  const lavQuimico = {}
  const lavProg = {}
  const progTotal = {}
  const quimicoTotal = {}

  lavs.forEach((lav, lavIndex) => {
    lavQuimico[lav] = {}
    lavProg[lav] = {}

    Object.keys(results[lav]).sort().forEach(prog => {
      progs.add(prog)
      if (!lavProg[lav][prog]) lavProg[lav][prog] = 0
      if (!progTotal[prog]) progTotal[prog] = 0

      Object.keys(results[lav][prog]).sort().forEach(quimico => {
        if (!lavQuimico[lav][quimico]) lavQuimico[lav][quimico] = 0
        if (!quimicoTotal[quimico]) quimicoTotal[quimico] = 0

        const count = results[lav][prog][quimico] * props.mult
        lavQuimico[lav][quimico] += count
        lavProg[lav][prog] += count
        progTotal[prog] += count
        quimicoTotal[quimico] += count
      })
    })
  })

  return (
    <>
      <Row className='mt-4'>
        <Col md={12}><h4 style={{ textAlign: 'center' }}>Químico consumido en lavadas totales</h4></Col>
        <Col>
          <div className='table-responsive'>
            <table id='ReporteKilogramos' className='table table-striped'>
              <thead>
                <tr>
                  {Object.keys(quimicoTotal).map(quimico => <th scope='col' key={quimico}>{quimico}</th>)}
                  <th scope='col'>TOTAL</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  {Object.values(quimicoTotal).map((quimico, index) => <td key={index}>{parse(quimico)}</td>)}
                  <td>{parse(Object.values(quimicoTotal).reduce((a, b) => a + b))}</td>
                </tr>
              </tbody>
            </table>
          </div>
        </Col>
      </Row>

      <Row className='mt-5'>
        <Col md={12}><h4 style={{ textAlign: 'center' }}>Químico consumido en lavadas {props.lavadas} {props.reporte} {props.dates.map(d => DateTime.fromISO(d).toFormat('yyyy-MM-dd')).join(' - ')}</h4></Col>
        <Col>
          <div className='table-responsive'>
            <table id='ReporteKilogramos' className='table table-striped'>
              <thead>
                <tr>
                  <th scope='col'>Lavadora</th>
                  <th scope='col'>Químico</th>
                  {[...progs].map(prog => <th scope='col' key={prog}>{prog.replace('_', ' ')}</th>)}
                  <th scope='col'>Total Químico</th>
                </tr>
              </thead>
              <tbody>
                {Object.keys(lavQuimico).map((lav, lavIndex, inst) =>
                  <Fragment key={lav}>
                    {Object.keys(lavQuimico[lav]).map((quimico, quimicoIndex) => {
                      let total = 0
                      return (
                        <tr key={`${lav}_{${quimico}}`}>
                          <td>{quimicoIndex === 0 && lav}</td>
                          <td style={{ textTransform: 'capitalize', fontWeight: 'bold', fontStyle: 'italic' }}>{quimico}</td>
                          {[...progs].map(prog => {
                            const count = results[lav][prog]
                              ? results[lav][prog][quimico] * props.mult : 0
                            total += count
                            return (
                              <td key={prog}>{parse(count)}</td>
                            )
                          })}
                          <td>{parse(total)}</td>
                        </tr>
                      )
                    })}
                    <tr style={{ fontStyle: 'italic' }}>
                      <td>&nbsp;</td>
                      <td>TOTAL {lav}: </td>
                      {[...progs].map(prog => <td key={prog}>{parse(lavProg[lav][prog])}</td>)}
                      <td>{parse(Object.values(lavProg[lav]).reduce((a, b) => a + b))}</td>
                    </tr>
                    {lavIndex + 1 !== inst.length && <Spacer />}
                  </Fragment>
                )}
                <Spacer />
                <tr style={{ fontWeight: 'bold', backgroundColor: '#b5e6f9' }}>
                  <td>&nbsp;</td>
                  <td>TOTAL POR PROGRAMA: </td>
                  {[...progs].map(prog => <td key={prog}>{parse(progTotal[prog])}</td>)}
                  <td>{parse(Object.values(progTotal).reduce((a, b) => a + b))}</td>
                </tr>
              </tbody>
            </table>
          </div>
        </Col>
      </Row>
    </>
  )
}

function ReporteFecha (props) {
  const results = props.results
  const progs = new Set()
  const result = {}
  const dateTotal = {}
  const lavTotal = {}

  Object.keys(results).sort().forEach(date => {
    Object.keys(results[date]).sort().forEach(lav => {
      Object.keys(results[date][lav]).sort().forEach(prog => {
        progs.add(prog)
        Object.keys(results[date][lav][prog]).sort().forEach(quimico => {
          if (!result[date]) result[date] = { [lav]: { [quimico]: { [prog]: 0 } } }
          if (!result[date][lav]) result[date][lav] = { [quimico]: { [prog]: 0 } }
          if (!result[date][lav][quimico]) result[date][lav][quimico] = { [prog]: 0 }
          if (!result[date][lav][quimico][prog]) result[date][lav][quimico][prog] = 0

          if (!dateTotal[date]) dateTotal[date] = { [prog]: 0 }
          if (!dateTotal[date][prog]) dateTotal[date][prog] = 0

          if (!lavTotal[date]) lavTotal[date] = { [date]: { [prog]: 0 } }
          if (!lavTotal[date][lav]) lavTotal[date][lav] = { [prog]: 0 }
          if (!lavTotal[date][lav][prog]) lavTotal[date][lav][prog] = 0

          const count = results[date][lav][prog][quimico] * props.mult
          result[date][lav][quimico][prog] += count
          lavTotal[date][lav][prog] += count
          dateTotal[date][prog] += count
        })
      })
    })
  })

  return (
    <>
      <Row className='mt-5'>
        <Col md={12}><h4 style={{ textAlign: 'center' }}>Químico consumido en lavadas {props.lavadas} {props.reporte} {props.dates.map(d => DateTime.fromISO(d).toFormat('yyyy-MM-dd')).join(' - ')}</h4></Col>
        <Col>
          <div className='table-responsive'>
            <table id='ReporteKilogramos' className='table table-striped'>
              <thead>
                <tr>
                  <th scope='col'>Fecha</th>
                  <th scope='col'>Lavadora</th>
                  <th scope='col'>Químico</th>
                  {[...progs].map(prog => <th scope='col' key={prog}>{prog.replace('_', ' ')}</th>)}
                  <th scope='col'>Total Quimico</th>
                </tr>
              </thead>
              <tbody>
                {Object.keys(result).sort().map((date, dateIndex) =>
                  <Fragment key={`${date}`}>
                    {dateIndex > 0 && <Spacer />}
                    {Object.keys(result[date]).sort().map((lav, lavIndex) =>
                      <Fragment key={`${date}_${lav}`}>
                        {lavIndex > 0 && <Spacer />}
                        {Object.keys(result[date][lav]).sort().map((quimico, quimicoIndex) =>
                          <tr key={`${date}_${lav}_${quimico}`}>
                            <td>{quimicoIndex === 0 && date}</td>
                            <td>{quimicoIndex === 0 && lav}</td>
                            <td style={{ textTransform: 'capitalize', fontWeight: 'bold', fontStyle: 'italic' }}>{quimico}</td>
                            {[...progs].map(prog => <td key={prog}>{parse(result[date][lav][quimico][prog])}</td>)}
                            <td>{parse(Object.values(result[date][lav][quimico]).reduce((a, b) => a + b))}</td>
                          </tr>
                        )}
                        <tr key={`${date}_${lav}`} style={{ fontStyle: 'italic' }}>
                          <td>&nbsp;</td>
                          <td>&nbsp;</td>
                          <td>TOTAL {lav}</td>
                          {[...progs].map(prog => <td key={prog}>{parse(lavTotal[date][lav][prog])}</td>)}
                          <td>{parse(Object.values(lavTotal[date][lav]).reduce((a, b) => a + b))}</td>
                        </tr>
                      </Fragment>
                    )}
                    <tr key={`${date}`} style={{ fontWeight: 'bold', backgroundColor: '#b5e6f9' }}>
                      <td>&nbsp;</td>
                      <td>&nbsp;</td>
                      <td>TOTAL {date}</td>
                      {[...progs].map(prog => <td key={prog}>{parse(dateTotal[date][prog])}</td>)}
                      <td>{parse(Object.values(dateTotal[date]).reduce((a, b) => a + b))}</td>
                    </tr>

                  </Fragment>
                )}
              </tbody>
            </table>
          </div>
        </Col>
      </Row>
    </>
  )
}

export default function Quimicos (showRender, appState) {
  const routes = {}

  routes['/quimicos'] = () => <Content showRender={showRender} appState={appState} />
  return routes
}
