import { get, post } from 'axios'
import { toast } from 'react-toastify'

export const postAPI = (path, data) => {
  return post(getAPIUrl(path), data).catch(function (error) {
    console.log(error.response)
    // toastr.error(error.response.data || 'Error de servidor')
    toast.error((error.response && error.response.data ? error.response.data : false) || error.message || 'Error de servidor', {
      position: toast.POSITION.TOP_LEFT
    }, { autoClose: 5000 })
  })
}

export const getAPI = (path, data) => {
  return get(getAPIUrl(path), data).catch(function (error) {
    console.log(error.response)
    // toastr.error(error.response.data || 'Error de servidor')
    toast.error((error.response && error.response.data ? error.response.data : false) || error.message || 'Error de servidor', {
      position: toast.POSITION.TOP_LEFT
    }, { autoClose: 5000 })
  })
}

export const getAPIUrl = path => {
  return buildPath(window.location.origin, 'api', path)
}

/* eslint-disable no-useless-escape */
const buildPath = (...args) => {
  return args.map((part, i) => {
    if (i === 0) {
      return part.trim().replace(/[\/]*$/g, '')
    } else {
      return part.trim().replace(/(^[\/]*|[\/]*$)/g, '')
    }
  }).filter(x => x.length).join('/')
}
