import { Component } from 'react'
import axios from 'axios'
import { toast } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.min.css'
import '../css/login.css'
import { Col } from 'reactstrap'
import { navigate } from 'hookrouter'

import queryString from 'query-string'
const { key, user } = queryString.parse(window.location.search)

export default class Recover extends Component {
  handleLogin = (event) => {
    const serialData = { key, user }
    for (let i = 0; i < event.target.length; i++) {
      serialData[event.target[i].name] = event.target[i].value
    }

    axios.post('/api/user/recover/attempt', serialData).then(function (response) {
      toast.success('¡Ingreso correcto!', {
        position: toast.POSITION.TOP_CENTER
      }, {
        autoClose: 5000
      })

      navigate('/')
    })
      .catch(function (error) {
        console.log(error.response)
        // toastr.error(error.response.data || 'Error de servidor')
        toast.error((error.response && error.response.data ? error.response.data : false) || error.message || 'Error de servidor', {
          position: toast.POSITION.TOP_LEFT
        }, { autoClose: 5000 })
      })

    event.preventDefault()
  }

  render () {
    return (
      <Col md={12} className='d-flex align-items-center justify-content-center' style={{ marginTop: '200px' }}>
        <form onSubmit={this.handleLogin} className=''>
          <div className='form-row' style={{ fontSize: '16px' }}>
            <div className='form-group mx-auto'>
              <label className='ml-2' htmlFor='pass'>Nueva Contraseña</label>
              <input type='text' className='form-control ml-2' id='pass' name='pass' required='required' />
            </div>
            <div className='form-group mx-auto'>
              <button className='btn btn-primary ml-3 lol' type='submit'>Ingresar</button>
            </div>
          </div>
        </form>
        <div />
      </Col>
    )
  }
}
