import { useState, useEffect } from 'react'
import axios, { CancelToken } from 'axios'
import Chart from 'react-apexcharts'
import es from 'apexcharts/dist/locales/es.json'
import { DateTime } from 'luxon'

import { Col, Row } from 'reactstrap'
import DatePicker from '../componentes/DatePicker'
import State from '../componentes/States'

const combine = {
  Cobijas: 'Leve',
  Sangrante: 'Muy_Sucia',
  Sabanas_carit: 'Muy_Sucia',
  Operaciones: 'Muy_Sucia'
}

const pallete = ['#008FFB', '#00E396', '#FEB019', '#FF4560', '#775DD0', '#D10000', '#CC5803', '#7C0B2B']
const options = {
  chart: { type: 'bar', locales: [es], defaultLocale: 'es' },
  colors: pallete,

  plotOptions: {
    bar: {
      endingShape: 'rounded',
      columnWidth: '60%',
      distributed: true,
      dataLabels: {
        position: 'top'
      }
    }
  },
  dataLabels: {
    enabled: true
  },
  stroke: {
    show: true,
    width: 2,
    colors: ['transparent']
  },
  xaxis: { categories: [/* fill on render method */], axisTicks: { show: true, height: 5 } },
  fill: {
    opacity: 1
  },
  tooltip: {
    followCursor: true,
    shared: true,
    y: {
      formatter: function (val) {
        if (val) return `${val} kilogramos`
      }
    }
  }
}

export default function Content (props) {
  const [dates, setDates] = useState()
  const [state, setState] = useState('')
  const [data, setData] = useState()

  useEffect(() => {
    if (!dates) return
    let cancel

    setState('loading')

    axios.get('/api/get/kilogramos/total/total', {
      params: { dates }
    }, { cancelToken: new CancelToken(function executor (c) { cancel = c }) }).then(res => {
      setState('')
      setData(res.data)
    }).catch(err => {
      if (!axios.isCancel(err)) {
        console.log(err)
        setData(undefined)
        setState('error')
      }
    })

    return () => {
      if (cancel) cancel()
    }
  }, [dates])

  return (
    <>
      <Row className='text-center justify-content-center'><h2>Gráfico Facturación</h2></Row>
      <Row className='justify-content-center'>
        <Col md='auto'><DatePicker simple handleDate={strings => setDates(strings.map(e => DateTime.fromJSDate(e).toFormat('yyyy-MM-dd')))} id='datepick' /></Col>
      </Row>
      <Row className='justify-content-center mb-5'>
        <Col md='auto' className='mt-3 d-flex justify-content-center'>
          <span style={{ color: '#4158d4' }} className='mr-1'>Muy Sucia </span><span> = Muy Sucia + Sangrante +  Sábanas Carit +  Operaciones</span>
        </Col>
        <Col md='auto' className='mt-3 d-flex justify-content-center'>
          <span style={{ color: '#4158d4' }}>Leve </span><span>= Leve + Cobijas</span>
        </Col>
      </Row>
      <Row>
        <Col md={12}>
          <State state={state} />
          {data && <Grafico data={data.results} />}
        </Col>
      </Row>
    </>
  )
}

function Grafico (props) {
  const labelSet = new Set()
  const data = []

  Object.keys(props.data).sort().forEach((lav, lavIndex) => {
    Object.keys(props.data[lav]).sort().forEach(progRaw => {
      const prog = combine[progRaw] ? combine[progRaw] : progRaw
      labelSet.add(prog)

      const progIndex = [...labelSet].indexOf(prog)
      if (!data[progIndex]) data[progIndex] = props.data[lav][progRaw]
      else data[progIndex] += props.data[lav][progRaw]
    })
  })
  labelSet.add('TOTAL')

  options.xaxis.categories = [...(labelSet)].map(l => l.replace('carit', 'Carit'))
  data.push(data.reduce((a, b) => a + b))

  const series = [{ data }]

  return (
    <Chart options={options} series={series} type='bar' height={550} />
  )
}
