import { Component } from 'react'
import axios from 'axios'
import { toast } from 'react-toastify'

const capitalize = s => {
  if (typeof s !== 'string') return ''
  return s.charAt(0).toUpperCase() + s.slice(1)
}

class Lavadora extends Component {
  state = {
    show: true,
    programa: '',
    quimicos: { }
  }

  myCallback = (dataFromChild) => {
    this.setState(dataFromChild)
  }

  render () {
    if (this.state.show) {
      return (
        <div className='col-md-4 Lavadora'>
          <div className='numeroLavadora row'>
            <h3>{this.props.name}</h3>
            {/* <button className='btn btn-info' onClick={() => this.setState({ show: !this.state.show })}><span className='glyphicon glyphicon-eye-open' aria-hidden='true' /></button> */}
          </div>
          <div className='cuerpoLavadora row'>
            <div className='contenedor-botones-lavadora'>
              {
                this.state.programa === ''
                  ? (
                    <div>
                      {Object.keys(this.props.programas).sort().map((programa, index) => <ButtonPrograma key={programa} name={programa} updateState={this.myCallback} first={index === 0} />)}
                    </div>
                  )
                  : (
                    <div>
                      <button className='cerrarIngresarQuimicos my-3' onClick={() => this.setState({ programa: '' })}>Volver</button>
                      {
                        Object.keys(this.props.programas[this.state.programa].tags).map(quimico =>
                          <TagInput key={quimico} quimico={quimico} lavadora={this.props.name} programa={this.state.programa} value={this.props.programas[this.state.programa].tags[quimico].data.value} lavadoraData={this.props.lavadoraData} />
                        )
                      }
                    </div>
                  )
              }
            </div>

          </div>

        </div>)
    } else {
      return (
        <div className='col-md-1 Lavadora'>
          <div className='row numeroLavadora'>
            <h3>{this.props.name}</h3>
            {/* <button className='btn btn-info btn-nomargin' onClick={() => this.setState({ show: !this.state.show })}><span className={'glyphicon glyphicon-eye-close'} aria-hidden='true' /></button> */}
          </div>

          <div className='cuerpoLavadora row'>
            <div className='contenedor-botones-lavadora'><h3 className='verticaltext'>LAV01</h3></div>
          </div>
        </div>
      )
    }
  }
}

class TagInput extends Component {
  state= { disabled: false, value: this.props.value }
  timeout = null

  sendTag = function (ev) {
    clearTimeout(this.timeout)
    this.setState({ value: ev.target.value }, () => {
      this.timeout = setTimeout((programa, quimico, lavadora) => {
        this.setState({ disabled: true })
        axios.post('/api/post/tag', { value: this.state.value, path: `/San Juan/Tiempos/${lavadora}/${programa}/${quimico}` }).then(res => {
          this.setState({ disabled: false })
          this.props.lavadoraData(lavadora, programa, quimico, ev.target.value)
        }).catch(err => {
          console.log(err.response.data)

          this.setState({ disabled: false, value: this.props.value }, () => {
            toast.error(`Fallo al actualizar ${JSON.parse(err.config.data).path}`, {
              position: toast.POSITION.TOP_LEFT
            }, { autoClose: 5000 })
          })
        })
      }, 1000, this.props.programa, this.props.quimico, this.props.lavadora)
    })
  }

  render () {
    return (
      <div key={this.props.quimico} className='row w-100 px-5 justify-content-center ingresarQuimicos'>
        <div className='col-4'>
          <p>{capitalize(this.props.quimico)}: </p>
        </div>
        <div className='col-auto'>
          <input style={{ width: '50px' }} type='text' size='4' disabled={this.state.disabled} value={this.state.value} onChange={this.sendTag.bind(this)} />
        </div>
      </div>
    )
  }
}

class ButtonPrograma extends Component {
  render () {
    // console.log(this.props.updateState)
    return (
      <button
        className='abrirButtonPrograma my-3' onClick={() => this.props.updateState({
          programa: this.props.name,
          quimicos: {
            show: true,
            items: this.props.quimicos
          }
        })}
      >{this.props.name}
      </button>
    )
  }
}

export default class RowLavadoras extends Component {
  state = { data: {} }

  refreshTiempos = () => {
    axios.get('/api/get/tiempos').then(res => {
      this.setState({ data: res.data })
    })
  }

  lavadoraData = (lavadora, programa, quimico, value) => {
    const newData = Object.assign(this.state.data)
    newData[lavadora].groups[programa].tags[quimico].data.value = value

    this.setState({ data: newData })
  }

  componentDidMount () {
    this.refreshTiempos()
  }

  render () {
    return (
      <div className='row'>
        {
          Object.keys(this.state.data).map(lavadora =>
            <Lavadora key={lavadora} name={lavadora} programas={this.state.data[lavadora].groups} lavadoraData={this.lavadoraData} />
          )
        }
      </div>
    )
  }
}
