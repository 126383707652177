import { Component } from 'react'
import { Container } from 'reactstrap'

import 'bootstrap/dist/css/bootstrap.css'
import '@fortawesome/fontawesome-free/css/all.css'

import './css/Index.scss'

import NavSide from './js/Navbar'
import classNames from 'classnames'
import { ToastContainer } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.min.css'

import Login from './js/Login'
import Usuarios from './js/Usuarios'
import Index from './js/Index'
import Config from './js/config/Config'
import Kilogramos from './js/kilogramos/Kilogramos'
import Quimicos from './js/quimicos/quimicos'
import Factura from './js/factura/Factura'
import Inventario from './js/inventario/inventario'
import Recover from './js/Recover'
// import NotFoundPage from './js/404'

import { useRoutes } from 'hookrouter'

let routes = {}

function AppRoutes () {
  const routeResult = useRoutes(routes)

  return routeResult || <Index />
}

class App extends Component {
  state = {
    signedIn: process.env.REACT_APP_ENV === 'dev',
    sidebarBg: true,
    borderRadius: true,
    theme: 'light-theme',
    toggled: false,
    pinned: true
  }
  // themes: default-theme legacy-theme chiller-theme ice-theme cool-theme light-theme

  appState = (data, cb) => this.setState(data, cb)

  render () {
    routes = {}
    routes['/usuarios'] = () => <Usuarios showRender={this.state.state === 'ready'} appState={this.appState.bind(this)} />
    routes['/recover/attempt'] = () => <Recover showRender={this.state.state === 'ready'} appState={this.appState.bind(this)} />
    Object.assign(routes, Config(this.state.state === 'ready', this.appState.bind(this)))
    Object.assign(routes, Factura(this.state.state === 'ready', this.appState.bind(this)))
    Object.assign(routes, Kilogramos(this.state.state === 'ready', this.appState.bind(this)))
    Object.assign(routes, Quimicos(this.state.state === 'ready', this.appState.bind(this)))
    Object.assign(routes, Inventario(this.state.state === 'ready', this.appState.bind(this)))

    return (
      <div className={classNames({ 'sidebar-bg': this.state.sidebarBg, 'border-radius-on': this.state.borderRadius, pinned: this.state.pinned, toggled: this.state.toggled }, this.state.theme)}>
        <ToastContainer />
        <NavSide signedIn={this.state.signedIn} appState={this.appState} />
        <div className='page-content'>
          <Container className='pt-3'>
            {this.state.signedIn || window.location.pathname === '/recover/attempt' ? <AppRoutes appState={this.appState} /> : <Login appState={this.appState.bind(this)} />}
          </Container>
        </div>
      </div>
    )
  }
}

export default App
