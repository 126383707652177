import { Component, createRef } from 'react'
import { toast } from 'react-toastify'
import { getAPI, postAPI } from '../FormUtil'

import { Col, Row, Button, Form, FormGroup, Input, Label } from 'reactstrap'
const equal = require('deep-equal')

// Tabla
export default class TablaDeInformacion extends Component {
  state = { sitios: [] }

  componentDidMount= () => {
    getAPI('inventario/sitio').then(res => {
      this.setState({ sitios: res.data })
    })
    this.props.appState({ header: '', state: 'ready' })
  }

  render () {
    return (
      <div className={this.props.showRender ? '' : 'hideRender'}>
        <Row className='mt-2 justify-content-center'><h2>Control de Inventarios</h2></Row>
        {this.state.sitios.map(e => <><Sitio consulta={this.props.consulta} refreshSitios={this.componentDidMount} key={e.id} id={e.id} nombre={e.nombre} /><hr /></>)}
      </div>

    )
  }
};

class Sitio extends Component {
  state = { items: {}, inventario: {}, changes: {} }
  itemSelect = createRef()
  observacion = createRef()
  cantidadRefs = {}

  componentDidMount () {
    getAPI('inventario/item').then(res => {
      const items = {}
      res.data.forEach(e => { items[e.id] = e.nombre })
      this.setState({ items: items })
    })
    getAPI(`inventario/inventario/${this.props.id}`).then(res => {
      const items = {}
      res.data.forEach(e => { items[e.item] = e.cantidad })
      this.setState({ inventario: items, changes: {} })
    })
  }

  handleSubmitChanges = event => {
    postAPI('inventario/inventario', { observacion: this.observacion.current.value, inventario: this.state.inventario, changes: this.state.changes, sitio: this.props.id }).then(() => {
      this.componentDidMount()
      toast.success(`Inventario "${this.props.nombre}" actualizado`)
      this.props.refreshSitios()
    })
    event.persist()
    event.preventDefault()
  }

  handleAddItem = () => {
    const changes = this.state.changes
    changes[this.itemSelect.current.value] = 1
    this.setState({ changes: changes })
  }

  handleChangeCantidad = (id, ammount) => {
    this.cantidadRefs[id].current.value = parseFloat(this.cantidadRefs[id].current.value) + ammount
    this.updateChanges(id)
  }

  updateChanges = id => {
    const changes = this.state.changes
    changes[id] = parseFloat(this.cantidadRefs[id].current.value)
    if (changes[id] === this.state.inventario[id]) delete changes[id]
    if (changes[id] === 0 && !this.state.inventario[id]) delete changes[id]
    this.setState({ changes: changes })
  }

  render () {
    const invKeys = Object.keys(this.state.inventario).filter(e => this.state.changes[e] === undefined)
    const rows = []

    const disabled = equal(this.state.changes, {})

    rows.push(...invKeys.map(e => { return { id: e, cantidad: this.state.inventario[e], nombre: this.state.items[e] } }))
    rows.push(...Object.keys(this.state.changes).map(e => { return { id: e, cantidad: this.state.changes[e], nombre: this.state.items[e] } }))
    rows.sort((a, b) => a.id - b.id)

    return (
      <>
        <Row>
          <Col md={12}>
            <Form onSubmit={this.handleSubmitChanges}>
              <legend>{this.props.nombre}</legend>
              <div className='table-responsive'>
                <table className='table table-striped' style={{ width: 'auto' }}>
                  <thead>
                    <tr>
                      <th scope='col'>Código</th>
                      <th scope='col'>Nombre</th>
                      <th scope='col'>Cantidad</th>
                      {this.props.consulta ? null : <th>Control de fracciones de estañón</th>}
                    </tr>
                  </thead>
                  <tbody>
                    {rows.map(e => {
                      if (!this.cantidadRefs[e.id]) this.cantidadRefs[e.id] = createRef()
                      return (
                        <tr key={e.id}>
                          <td>{e.id}</td><td>{e.nombre}</td>
                          {this.props.consulta
                            ? <td>{e.cantidad}</td>
                            : <td><Input onChange={event => this.updateChanges(e.id)} innerRef={this.cantidadRefs[e.id]} step='0.25' bsSize='sm' type='number' style={{ width: '60px' }} defaultValue={e.cantidad} /></td>}
                          <td>
                            {this.props.consulta ? null
                              : (
                                <div className='mt-auto'>
                                  <Button className='py-0 mr-3' color='primary' onClick={() => this.handleChangeCantidad(e.id, -0.5)}>- 1/2</Button>
                                  <Button className='py-0 mr-3' color='primary' onClick={() => this.handleChangeCantidad(e.id, -0.25)}>- 1/4</Button>
                                  <Button className='py-0 mr-3' color='primary' onClick={() => this.handleChangeCantidad(e.id, 0.25)}>+ 1/4</Button>
                                  <Button className='py-0 mr-3' color='primary' onClick={() => this.handleChangeCantidad(e.id, 0.5)}>+ 1/2</Button>
                                </div>
                              )}
                          </td>
                        </tr>
                      )
                    }
                    )}
                  </tbody>
                </table>
              </div>
              {this.props.consulta ? null : (
                <>
                  <Row>
                    <Col md={6}>
                      <FormGroup>
                        <Label for='nombre'>Observacion</Label>
                        <Input type='text' innerRef={this.observacion} name='observacion' defaultValue='N/A' required maxLength={255} />
                      </FormGroup>
                    </Col>
                  </Row>
                  <Row>
                    <Col md={4}>
                      <Input innerRef={this.itemSelect} type='select' name='select'>
                        {Object.keys(this.state.items)
                          .filter(e => !this.state.inventario[e] && !this.state.changes[e])
                          .map(e => <option value={e} key={e}>{e}) {this.state.items[e]}</option>)}
                      </Input>
                    </Col>

                    <FormGroup className='mr-2 mb-0'>
                      <Button color='primary' onClick={this.handleAddItem}>Agregar Ítem</Button>
                    </FormGroup>
                    <FormGroup className='mr-2 mb-0'>
                      <Button color='primary' disabled={disabled}>Guardar Cambios</Button>
                    </FormGroup>
                  </Row>
                </>
              )}
            </Form>
          </Col>
        </Row>
      </>
    )
  }
}
