import { useState, useEffect } from 'react'
import { DateTime } from 'luxon'
import axios, { CancelToken } from 'axios'
import Chart from 'react-apexcharts'

import { Col, Row } from 'reactstrap'
import DatePicker from '../componentes/DatePicker'
import State from '../componentes/States'

import es from 'apexcharts/dist/locales/es.json'

const pallete = ['#008FFB', '#00E396', '#FEB019', '#FF4560', '#775DD0', '#D10000', '#CC5803', '#7C0B2B']

export default function Content () {
  const [dates, setDates] = useState()
  const [state, setState] = useState('')
  const [lavadas, setLavadas] = useState()
  const [reporte, setReporte] = useState()
  const [data, setData] = useState()

  useEffect(() => {
    if (!dates || !lavadas || !reporte) return
    let cancel

    setState('loading')

    axios.get(`/api/get/kilogramos/${lavadas}/${reporte}`, {
      params: { dates }
    }, { cancelToken: new CancelToken(function executor (c) { cancel = c }) }).then(res => {
      setData(res.data)
      setState('')
    }).catch(err => {
      if (!axios.isCancel(err)) {
        console.log(err)
        setState('error')
      }
    })

    return () => {
      if (cancel) cancel()
    }
  }, [dates, lavadas, reporte])

  return (
    <>
      <Row className='mt-2 justify-content-center'><h2>Gráfico Kilogramos</h2></Row>
      <Row>
        <DatePicker
          handleDate={strings => setDates(strings.map(e => DateTime.fromJSDate(e).toFormat('yyyy-MM-dd')))} handleTipo={tipo => {
            setLavadas(tipo.lavadas)
            setReporte(tipo.reporte)
          }} id='datepick'
        />
      </Row>
      <Row className='mt-4'>
        <Col md={12}>
          <State state={state} />
          <Grafico data={data} />
        </Col>
      </Row>
    </>
  )
}

function Grafico (props) {
  const { data } = props
  return (
    data ? data.reporte === 'total' ? <GraficoReporte {...data} /> : <GraficoFecha {...data} /> : null
  )
}

function GraficoReporte (props) {
  const results = props.results
  const [filter, setFilter] = useState(Object.keys(results).sort())

  function handleFilter ({ target }) {
    let newFilter = [...filter]

    if (target.checked) newFilter.push(target.value)
    else newFilter = newFilter.filter(e => e !== target.value)

    setFilter(newFilter)
  }

  const series = []

  const lavs = Object.keys(results).sort()
  const filtered = lavs.filter(lav => filter.includes(lav))
  const progs = new Set()

  filtered.forEach((lav, lavIndex) => {
    Object.keys(results[lav]).sort().forEach(name => {
      const prog = name.replace('_', ' ')
      progs.add(prog)

      const progIndex = [...progs].indexOf(prog)
      if (!series[progIndex]) series[progIndex] = { name: prog, data: [] }

      const value = results[lav][name]
      series[progIndex].data[lavIndex] = value
    })
  })

  const options = {
    chart: { type: 'bar', locales: [es], defaultLocale: 'es', width: `${(filtered.length * 200) + 40}px` },
    colors: pallete,

    plotOptions: {
      bar: {
        endingShape: 'rounded',
        columnWidth: '60%',
        dataLabels: {
          position: 'top'
        }
      }
    },
    dataLabels: {
      enabled: true
    },
    stroke: {
      show: true,
      width: 2,
      colors: ['transparent']
    },
    xaxis: { categories: filtered, axisTicks: { show: true, height: 5 } },
    yaxis: {
      title: {
        text: 'Kilogramos lavados'
      }
    },
    fill: {
      opacity: 1
    },
    tooltip: {
      followCursor: true,
      shared: true,
      y: {
        formatter: function (val) {
          if (val) return `${val} kilogramos`
        }
      }
    }
  }

  return (
    <Row>
      <Row>
        <Col>
          {lavs.map(lav => (
            <div className='form-check form-check-inline' key={lav}>
              <input className='form-check-input' type='checkbox' name='lav' value={lav} defaultChecked onChange={handleFilter} />
              <label className='form-check-label'>{lav}</label>
            </div>
          ))}
        </Col>
      </Row>
      <Row style={{ overflowX: 'auto', overflowY: 'hidden' }}>
        <Col md={12}>
          <Chart options={options} series={series} type='bar' width={`${(filtered.length * 200) + 40}px`} height={550} />
        </Col>
      </Row>
    </Row>
  )
}

function GraficoFecha (props) {
  const results = props.results
  const [filter, setFilter] = useState(Object.keys(results).sort())

  function handleFilter ({ target }) {
    let newFilter = [...filter]

    if (target.checked) newFilter.push(target.value)
    else newFilter = newFilter.filter(e => e !== target.value)

    setFilter(newFilter)
  }

  const series = []

  const fechas = Object.keys(results)
  const filtered = fechas.filter(fecha => filter.includes(fecha))
  const progs = new Set()

  filtered.forEach((fecha, fechaIndex) => {
    Object.keys(results[fecha]).sort().forEach(lav => {
      Object.keys(results[fecha][lav]).sort().forEach(name => {
        const prog = name.replace('_', ' ')
        progs.add(prog)

        const progIndex = [...progs].indexOf(prog)
        if (!series[progIndex]) series[progIndex] = { name: prog, data: [] }

        const value = results[fecha][lav][name]
        series[progIndex].data[fechaIndex] = value
      })
    })
  })

  const options = {
    chart: { type: 'bar', locales: [es], defaultLocale: 'es', width: `${(filtered.length * 200) + 40}px` },
    colors: pallete,

    plotOptions: {
      bar: {
        endingShape: 'rounded',
        columnWidth: '60%',
        dataLabels: {
          position: 'top'
        }
      }
    },
    dataLabels: {
      enabled: true
    },
    stroke: {
      show: true,
      width: 2,
      colors: ['transparent']
    },
    xaxis: { categories: filtered, axisTicks: { show: true, height: 5 } },
    yaxis: {
      title: {
        text: 'Kilogramos lavados'
      }
    },
    fill: {
      opacity: 1
    },
    tooltip: {
      followCursor: true,
      shared: true,
      y: {
        formatter: function (val) {
          if (val) return `${val} kilogramos`
        }
      }
    }
  }

  return (
    <>
      <Row>
        <Col>
          {fechas.map(fecha => (
            <div className='form-check form-check-inline' key={fecha}>
              <input className='form-check-input' type='checkbox' name='fecha' value={fecha} defaultChecked onChange={handleFilter} />
              <label className='form-check-label'>{fecha}</label>
            </div>
          ))}
        </Col>
      </Row>
      <Row style={{ overflowX: 'auto', overflowY: 'hidden' }}>
        <Col md={12}>
          <Chart options={options} series={series} type='bar' width={`${(filtered.length * 200) + 40}px`} height={550} />
        </Col>
      </Row>
    </>
  )
}
