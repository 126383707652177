import Configuracion from './configuracion'
import Admin from './admin'
import Orden from './crearOrden'
import OrdenesActuales from './ordenesActuales'
import OrdenesFinalizados from './ordenesFinalizadas'

function Config (showRender, appState) {
  const routes = {}

  routes['/inventario/config'] = () => <Configuracion showRender={showRender} appState={appState} />
  routes['/inventario/admin'] = () => <Admin consulta={false} showRender={showRender} appState={appState} />
  routes['/inventario/admin/consulta'] = () => <Admin consulta showRender={showRender} appState={appState} />
  routes['/inventario/orden'] = () => <Orden showRender={showRender} appState={appState} />
  routes['/inventario/ordenes'] = () => <OrdenesActuales showRender={showRender} appState={appState} />
  routes['/inventario/ordenes/consulta'] = () => <OrdenesActuales consulta showRender={showRender} appState={appState} />
  routes['/inventario/ordenes/final'] = () => <OrdenesFinalizados showRender={showRender} appState={appState} />

  return routes
}

export default Config
