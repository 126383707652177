import { Component, createRef } from 'react'
import { toast } from 'react-toastify'
import { getAPI, postAPI } from '../FormUtil'

import { Col, Row, Button, Form, FormGroup, Label, Input } from 'reactstrap'

const equal = require('deep-equal')

// Tabla
export default class TablaDeInformacion extends Component {
  componentDidMount () {
    this.props.appState({ header: '', state: 'ready' })
  }

  render () {
    return (
      <div className={this.props.showRender ? '' : 'hideRender'}>
        <CrearOrden />
      </div>

    )
  }
};

class CrearOrden extends Component {
  state ={ items: {}, changes: {}, sitios: [], origen: null, timer: 0, confirmed: false }
  itemSelect = createRef()
  ordenId = createRef()
  destinoId = createRef()
  marchamo = createRef()
  transportista = createRef()
  cantidadRefs = {}

  componentDidMount () {
    getAPI('inventario/item').then(res => {
      const items = {}
      res.data.forEach(e => { items[e.id] = e.nombre })
      this.setState({ items: items })
    })

    getAPI('inventario/sitio').then(res => {
      this.setState({ sitios: res.data }, () => {
        getAPI(`/inventario/inventario/${this.state.sitios[0].id}`, {}).then(res => {
          const data = {}
          res.data.forEach(row => { data[row.item] = row.cantidad })
          this.setState({ origen: this.state.sitios[0].id, inventario: data })
        })
      })
    })
  }

  handleSubmitChanges = event => {
    if (this.state.confirmed) {
      postAPI('inventario/orden', { marchamo: this.marchamo.current.value, transportista: this.transportista.current.value, id: this.ordenId.current.value, origen: this.state.origen, destino: this.destinoId.current.value, changes: this.state.changes }).then(() => {
        this.componentDidMount()
        toast.success('Órden creada satisfactoriamente')
        // event.target.reset()
      })
      event.persist()
      event.preventDefault()
    } else {
      toast.info('Revise la órden durante 30 segundos')
      this.setState({ timer: 30 }, () => {
        const interval = setInterval(() => {
          if (this.state.timer > 0) {
            this.setState({ timer: this.state.timer - 1 })
          } else {
            clearInterval(interval)
            this.setState({ confirmed: true })
          }
        }, 1000)
      })
    }
  }

  handleAddItem = () => {
    const changes = this.state.changes
    changes[this.itemSelect.current.value] = 1
    this.setState({ changes: changes })
  }

  handleChangeCantidad = (id, ammount) => {
    this.cantidadRefs[id].current.value = parseFloat(this.cantidadRefs[id].current.value) + ammount
    this.updateChanges(id)
  }

  updateChanges = id => {
    const changes = this.state.changes
    changes[id] = parseFloat(this.cantidadRefs[id].current.value)
    if (changes[id] === 0) delete changes[id]
    this.setState({ changes: changes })
  }

  render () {
    const disabled = equal(this.state.changes, {})
    return (
      <Row>
        <Col md={12}>
          <Form>
            <legend><h2 className='text-center'>Crear Órden de Salida</h2></legend>
            <div className='table-responsive'>
              <table className='table table-striped' style={{ width: 'auto' }}>
                <thead>
                  <tr>
                    <th scope='col'>Código</th>
                    <th scope='col'>Nombre</th>
                    <th scope='col'>Cantidad</th>
                    <th>Control de fracciones de estañón</th>
                  </tr>
                </thead>
                <tbody>
                  {
                    Object.keys(this.state.changes).map(e => {
                      if (!this.cantidadRefs[e]) this.cantidadRefs[e] = createRef()
                      return (
                        <tr key={e}>
                          <td>{e}</td><td>{this.state.items[e]}</td>
                          <td><Input max={this.state.inventario[e] || 0} innerRef={this.cantidadRefs[e]} onChange={event => this.updateChanges(e)} step='0.25' bsSize='sm' min={0} defaultValue={1} type='number' style={{ width: '60px' }} /></td>
                          <td>
                            <div className='mt-auto'>
                              <Button className='py-0 mr-3' color='primary' onClick={() => this.handleChangeCantidad(e, -0.5)}>- 1/2</Button>
                              <Button className='py-0 mr-3' color='primary' onClick={() => this.handleChangeCantidad(e, -0.25)}>- 1/4</Button>
                              <Button className='py-0 mr-3' color='primary' onClick={() => this.handleChangeCantidad(e, 0.25)}>+ 1/4</Button>
                              <Button className='py-0 mr-3' color='primary' onClick={() => this.handleChangeCantidad(e, 0.5)}>+ 1/2</Button>
                            </div>
                          </td>
                        </tr>
                      )
                    })
                  }
                </tbody>
              </table>
            </div>
            <Row>
              <Col md={4}>
                <Label for='id'>No Órden</Label>
                <Input type='number' innerRef={this.ordenId} name='id' />
              </Col>

              <Col md={4}>
                <Label for='origen'>Origen</Label>
                <Input
                  type='select' onChange={e => {
                    e.persist()
                    getAPI(`/inventario/inventario/${e.target.value}`, {}).then(res => {
                      const data = {}
                      res.data.forEach(row => { data[row.item] = row.cantidad })
                      this.setState({ origen: e.target.value, inventario: data })
                    })
                  }} name='origen'
                >
                  {this.state.sitios.map(e => <option value={e.id} key={e.id}>{e.nombre}</option>)}
                </Input>
              </Col>

              <Col md={4}>
                <Label for='destino'>Destino</Label>
                <Input type='select' innerRef={this.destinoId} name='destino'>
                  {this.state.sitios.map(e => <option value={e.id} key={e.id}>{e.nombre}</option>)}
                </Input>
              </Col>
            </Row>
            <Row>
              <Col md={6}>
                <Label for='marchamo'>No Marchamo</Label>
                <Input type='text' innerRef={this.marchamo} name='marchamo' />
              </Col>
              <Col md={6}>
                <Label for='transportista'>Transportista</Label>
                <Input type='text' innerRef={this.transportista} name='transportista' />
              </Col>
            </Row>
            <Row className='mt-3'>
              <Col md={4}>
                <Input innerRef={this.itemSelect} type='select'>
                  {Object.keys(this.state.items).filter(e => this.state.changes[e] === undefined).map(e => <option value={e} key={e}>{this.state.items[e]}</option>)}
                </Input>
              </Col>
              <FormGroup className='mr-2 mb-0'>
                <Button color='primary' onClick={this.handleAddItem}>Agregar Ítem</Button>
              </FormGroup>
            </Row>
            <Row className='mt-3'>
              <Col>
                <FormGroup className='mr-2 mb-0'>
                  <Button onClick={this.handleSubmitChanges} color='primary' disabled={disabled || (!this.state.confirmed && this.state.timer > 0)}>{!this.state.confirmed && this.state.timer > 0 ? `Confirmar Salida (${this.state.timer})` : 'Confirmar Salida'}</Button>
                </FormGroup>
              </Col>
            </Row>
          </Form>
        </Col>
      </Row>
    )
  }
}
