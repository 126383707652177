import { Component, useState, useEffect } from 'react'
import $ from 'jquery'
import { Col, Row } from 'reactstrap'

import '../../lib/air-datepicker/dist/css/datepicker.min.css'
import '../../lib/air-datepicker/dist/js/datepicker.js'
import '../../lib/air-datepicker/dist/js/i18n/datepicker.es.js'

const mountFunc = context => {
  const selectFunc = (formattedDate, date, inst) => {
    const name = inst.el.name
    context.setState({ [name]: date })

    const { first, last } = context.state
    if (first && last) context.props.handleDate([first, last])

    if (name === 'first') context.last.update({ minDate: date })
    if (name === 'last') context.first.update({ maxDate: date })
  }

  context.first = $(`.${context.props.id}[name=first]`).datepicker({ onSelect: selectFunc, maxDate: new Date() }).data('datepicker')
  context.last = $(`.${context.props.id}[name=last]`).datepicker({ onSelect: selectFunc, maxDate: new Date() }).data('datepicker')
}

export default class DatePicker extends Component {
  state = {}
  first
  last

  componentDidMount () {
    mountFunc(this)
  }

  render () {
    return (
      <>
        <Col className='mt-3'>
          <h5>Seleccionar fechas:</h5>
          <div className='btn-group mr-3'>
            <label>Desde:<input style={{ height: '35px' }} name='first' type='text' data-language='es' className={`ml-1 datepicker-here ${this.props.id}`} /></label>
          </div>
          <div className='btn-group'>
            <label>Hasta:<input style={{ height: '35px' }} name='last' type='text' data-language='es' className={`ml-1 datepicker-here ${this.props.id}`} /></label>
          </div>
        </Col>
        {!this.props.simple && <Col className='mt-3'><TypePickers onHandleTipo={this.props.handleTipo} /></Col>}
      </>
    )
  }
}

function TypePickers (props) {
  const { onHandleTipo } = props
  const [lavadas, setLavadas] = useState('total')
  const [reporte, setReporte] = useState('total')

  useEffect(() => {
    onHandleTipo({ lavadas, reporte })
  }, [lavadas, reporte, onHandleTipo])

  function handleInput (ev) {
    const { name, value } = ev.target

    if (name === 'lavadas') setLavadas(value)
    else if (name === 'reporte') setReporte(value)
  }

  return (
    <>
      <Row className='justify-content-center'>
        <Col xs='auto' className='mt-md-3'>
          <h5 style={{ textAlign: 'center' }}>Tipo de lavadas:</h5>
          <div className='form-check form-check-inline'>
            <input className='form-check-input' type='radio' name='lavadas' value='buena' onChange={handleInput} />
            <label className='form-check-label'>Buenas</label>
          </div>
          <div className='form-check form-check-inline'>
            <input className='form-check-input' type='radio' name='lavadas' value='mala' onChange={handleInput} />
            <label className='form-check-label'>Malas</label>
          </div>
          <div className='form-check form-check-inline'>
            <input className='form-check-input' type='radio' name='lavadas' value='total' onChange={handleInput} defaultChecked />
            <label className='form-check-label'>Totales</label>
          </div>
        </Col>
        <Col xs='auto' className='mt-3'>
          <h5 style={{ textAlign: 'center' }}>Tipo de reporte:</h5>
          <div className='form-check form-check-inline'>
            <input className='form-check-input' type='radio' name='reporte' value='diario' onChange={handleInput} />
            <label className='form-check-label'>Diario</label>
          </div>
          <div className='form-check form-check-inline'>
            <input className='form-check-input' type='radio' name='reporte' value='mensual' onChange={handleInput} />
            <label className='form-check-label'>Mensual</label>
          </div>
          <div className='form-check form-check-inline'>
            <input className='form-check-input' type='radio' name='reporte' value='total' onChange={handleInput} defaultChecked />
            <label className='form-check-label'>Total</label>
          </div>
        </Col>
      </Row>
    </>
  )
}
