import { useState, useEffect } from 'react'
import axios from 'axios'
import { Container, Row, Col } from 'reactstrap'
import { navigate } from 'hookrouter'
import classname from 'classnames'

export default function Index () {
  const [items, setItems] = useState()

  useEffect(() => {
    axios.get('/api/itemList').then(response => setItems(response.data))
  }, [])

  return (
    <Container className='home mt-4'>
      <Row>
        {items && Object.entries(items).map(([name, items], i) => <Item name={name} items={items} key={i} />)}
      </Row>
    </Container>
  )
}

function Item ({ name, items }) {
  const [open, setOpen] = useState(false)

  return (
    <Col md={4} xs={6} className='px-4 mb-3'>
      <Row className='item px-2 py-4'>
        <Col md={12} className='d-flex align-items-center justify-content-center'>
          <div className='d-flex'>
            <img alt={name} src={`/img/${name}.png`} />
          </div>
        </Col>
        <Col md={12}>
          <span className={classname('mt-4 title', { open })} onClick={() => setOpen(!open)}>{name}</span>
        </Col>
        {open && (
          <Col xs='auto' className='mt-2 mx-auto'>
            {items && items.map(({ name, url }, i) => (
              <span key={i} onClick={() => navigate(url)} className='url mt-md-1'>{name}</span>
            ))}
          </Col>
        )}
      </Row>
    </Col>
  )
}
