import { Fragment, useState, useEffect } from 'react'
import { DateTime } from 'luxon'
import axios, { CancelToken } from 'axios'
import DatePicker from '../componentes/DatePicker'
import State from '../componentes/States'
import { Col, Row } from 'reactstrap'
import Spacer from '../componentes/Spacer'

export default function Content () {
  const [dates, setDates] = useState()
  const [state, setState] = useState('')
  const [lavadas, setLavadas] = useState()
  const [reporte, setReporte] = useState()
  const [data, setData] = useState()

  useEffect(() => {
    if (!dates || !lavadas || !reporte) return
    let cancel

    setState('loading')

    axios.get(`/api/get/kilogramos/${lavadas}/${reporte}`, { params: { dates } },
      { cancelToken: new CancelToken(function executor (c) { cancel = c }) }).then(res => {
      setData(res.data)
      setState('')
    }).catch(err => {
      if (!axios.isCancel(err)) {
        console.log(err)
        setState('error')
      }
    })

    return () => {
      if (cancel) cancel()
    }
  }, [dates, lavadas, reporte])

  return (
    <>
      <Row className='mt-2 justify-content-center'><h1>Reporte Kilogramos</h1></Row>
      <Row>
        <DatePicker
          handleDate={strings => setDates(strings.map(e => DateTime.fromJSDate(e).toFormat('yyyy-MM-dd')))} handleTipo={tipo => {
            setLavadas(tipo.lavadas)
            setReporte(tipo.reporte)
          }} id='datepick'
        />
      </Row>

      <Row className='mt-4'>
        <Col md={12}>
          <State state={state} />
          <Reporte data={data} />
        </Col>
      </Row>
    </>
  )
}

function Reporte (props) {
  const { data } = props
  return (
    data ? data.reporte === 'total' ? <ReporteTotal {...data} /> : <ReporteFecha {...data} /> : null
  )
}

function ReporteFecha (props) {
  const results = props.results
  const dates = Object.keys(results).sort()
  const lavs = Object.keys(results[dates[0]]).sort()
  const progTotal = {}

  let total = 0
  dates.forEach(date => {
    lavs.forEach((lav, lavIndex) => {
      if (results[date][lav]) {
        Object.keys(results[date][lav]).sort().forEach(prog => {
          if (!progTotal[prog]) progTotal[prog] = 0
        })
      }
    })
  })

  return (
    <Row>
      <Col md={12} className='mt-4 mb-2'><h4 style={{ textAlign: 'center' }}>Kilogramos en lavadas {props.lavadas} {props.reporte} {props.dates.map(d => DateTime.fromISO(d).toFormat('yyyy-MM-dd')).join(' - ')}</h4></Col>
      <Col>
        <div className='table-responsive'>
          <table id='ReporteKilogramos' className='table table-striped'>
            <thead>
              <tr>
                <th scope='col'>Fecha</th>
                <th scope='col'>Lavadora</th>
                {Object.keys(progTotal).map(formula => <th scope='col' key={formula}>{formula.replace('_', ' ')}</th>)}
                <th scope='col'>Total Kilogramos</th>
              </tr>
            </thead>
            <tbody>
              {dates.map((date, dateIndex) => {
                let totalDate = 0
                const totalProgDate = {}
                return (
                  <Fragment key={date}>
                    {dateIndex > 0 && <Spacer />}
                    {lavs.map((lav, lavIndex) => {
                      let totalLav = 0
                      return (
                        <tr key={`${date}_${lavIndex}`}>
                          <td>{lavIndex === 0 && date}</td>
                          <td>{lav}</td>
                          {Object.keys(progTotal).map(prog => {
                            let count = 0
                            if (!totalProgDate[prog]) totalProgDate[prog] = 0

                            if (results[date][lav]) {
                              count = results[date][lav][prog] ? results[date][lav][prog] : ''
                              if (count !== '') {
                                totalLav += count
                                progTotal[prog] += count

                                totalProgDate[prog] += count
                                totalDate += count
                                total += count
                              }
                            }
                            return (
                              <td key={prog}>{count}</td>
                            )
                          })}
                          <td>{totalLav}</td>
                        </tr>
                      )
                    })}
                    <tr style={{ fontStyle: 'italic' }}>
                      <td>&nbsp;</td>
                      <td>TOTAL {date}</td>
                      {Object.keys(progTotal).map(prog => <td key={prog}>{totalProgDate[prog]}</td>)}
                      <td>{totalDate}</td>
                    </tr>
                  </Fragment>
                )
              })}
              <Spacer />
              <tr key='Total' style={{ fontWeight: 'bold', backgroundColor: '#b5e6f9' }}>
                <td>&nbsp;</td>
                <td>TOTAL POR PROGRAMA</td>
                {Object.keys(progTotal).map(prog => <td key={prog}>{progTotal[prog]}</td>)}
                <td>{total}</td>
              </tr>
            </tbody>
          </table>
        </div>
      </Col>
    </Row>
  )
}

function ReporteTotal (props) {
  const results = props.results
  const lavs = Object.keys(results).sort()
  const progTotal = {}

  let total = 0

  lavs.forEach((lav, lavIndex) => {
    Object.keys(results[lav]).sort().forEach(prog => {
      if (!progTotal[prog]) progTotal[prog] = 0
    })
  })

  return (
    <Row>
      <Col md={12} className='mt-4 mb-2'><h4 style={{ textAlign: 'center' }}>Kilogramos en lavadas totales</h4></Col>
      <Col>
        <div className='table-responsive'>
          <table id='ReporteKilogramos' className='table table-striped'>
            <thead>
              <tr>
                <th scope='col'>Lavadora</th>
                {Object.keys(progTotal).map(formula => <th scope='col' key={formula}>{formula.replace('_', ' ')}</th>)}
                <th scope='col'>Total Kilogramos</th>
              </tr>
            </thead>
            <tbody>
              {lavs.map((lav, lavIndex) => {
                let totalLav = 0
                return (
                  <tr key={`${lavIndex}`}>
                    <td>{lav}</td>
                    {Object.keys(progTotal).map(prog => {
                      const count = results[lav][prog] ? results[lav][prog] : ''
                      if (count !== '') {
                        totalLav += count
                        progTotal[prog] += count
                        total += count
                      }
                      return (
                        <td key={prog}>{count}</td>
                      )
                    })}
                    <td>{totalLav}</td>
                  </tr>
                )
              })}
              <tr key='Total' style={{ fontWeight: 'bold', backgroundColor: '#b5e6f9' }}>
                <td>TOTAL</td>
                {Object.keys(progTotal).map(prog => <td key={prog}>{progTotal[prog]}</td>)}
                <td>{total}</td>
              </tr>
            </tbody>
          </table>
        </div>
      </Col>
    </Row>
  )
}
