import Tarado from './tarado'
import Tiempos from './tiempos'

function Config (showRender, appState) {
  const routes = {}

  routes['/config/tarado'] = () => <Tarado showRender={showRender} appState={appState} />
  routes['/config/tiempos'] = () => <Tiempos showRender={showRender} appState={appState} />

  return routes
}

export default Config
