import { Component } from 'react'
import axios from 'axios'
import { toast } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.min.css'
import '../css/login.css'

export default class Login extends Component {
  state = { users: [], paths: [] }
  handleLogin = (event) => {
    const serialData = {}
    for (let i = 0; i < event.target.length; i++) {
      serialData[event.target[i].name] = event.target[i].value
    }

    axios.post('/api/register', serialData).then(function (response) {
      toast.success('¡Usuario agregado!', {
        position: toast.POSITION.TOP_CENTER
      }, {
        autoClose: 5000
      })
    })
      .catch(function (error) {
        console.log(error.response)
        // toastr.error(error.response.data || 'Error de servidor')
        toast.error((error.response && error.response.data ? error.response.data : false) || error.message || 'Error de servidor', {
          position: toast.POSITION.TOP_LEFT
        }, { autoClose: 5000 })
      })
    event.preventDefault()
  }

  componentDidMount () {
    axios.get('/api/users').then(response => this.setState(response.data))
  }

  handleDisable (checked, user) {
    axios.post('/api/user/toggle', { checked, user }).then(function (response) {
      toast.success(`Usuario ${user} actualizado`, {
        position: toast.POSITION.TOP_CENTER
      }, {
        autoClose: 5000
      })
    })
      .catch(function (error) {
        console.log(error.response)
        // toastr.error(error.response.data || 'Error de servidor')
        toast.error((error.response && error.response.data ? error.response.data : false) || error.message || 'Error de servidor', {
          position: toast.POSITION.TOP_LEFT
        }, { autoClose: 5000 })
      })
  }

  handleSite (checked, user, url) {
    axios.post('/api/user/site', { checked, user, url }).then(function (response) {
      toast.success(`Usuario ${user} actualizado`, {
        position: toast.POSITION.TOP_CENTER
      }, {
        autoClose: 5000
      })
    })
      .catch(function (error) {
        console.log(error.response)
        // toastr.error(error.response.data || 'Error de servidor')
        toast.error((error.response && error.response.data ? error.response.data : false) || error.message || 'Error de servidor', {
          position: toast.POSITION.TOP_LEFT
        }, { autoClose: 5000 })
      })
  }

  handlePass (e) {
    e.persist()
    e.preventDefault()
    const serialData = {}
    for (let i = 0; i < e.target.length; i++) {
      serialData[e.target[i].name] = e.target[i].value
    }

    axios.post('/api/user/pass', serialData).then(function (response) {
      toast.success(`Usuario ${serialData.key} actualizado`, {
        position: toast.POSITION.TOP_CENTER
      }, {
        autoClose: 5000
      })
    })
      .catch(function (error) {
        console.log(error.response)
        // toastr.error(error.response.data || 'Error de servidor')
        toast.error((error.response && error.response.data ? error.response.data : false) || error.message || 'Error de servidor', {
          position: toast.POSITION.TOP_LEFT
        }, { autoClose: 5000 })
      })
  }

  handleRecover (e) {
    e.persist()
    e.preventDefault()
    const serialData = {}
    for (let i = 0; i < e.target.length; i++) {
      serialData[e.target[i].name] = e.target[i].value
    }

    axios.post('/api/user/recover', serialData).then(function (response) {
      toast.success('Correo de recuperaciòn enviado', {
        position: toast.POSITION.TOP_CENTER
      }, {
        autoClose: 5000
      })
    })
      .catch(function (error) {
        console.log(error.response)
        // toastr.error(error.response.data || 'Error de servidor')
        toast.error((error.response && error.response.data ? error.response.data : false) || error.message || 'Error de servidor', {
          position: toast.POSITION.TOP_LEFT
        }, { autoClose: 5000 })
      })
  }

  render () {
    return (
      <>
        <div className='row'>
          <div className='col-md-6 border'>
            <form onSubmit={this.handleLogin}>
              <div className='form-group form-row mt-3'>
                <h3>Agregar Usuario</h3>
              </div>
              <div className='form-group form-row'>
                <div className='form-group'>
                  <label htmlFor='user'>Nombre de usuario</label>
                  <input type='text' className='form-control ' id='user' name='user' required='required' />
                </div>
                <div className='form-group'>
                  <label className='ml-2' htmlFor='pass'>Email</label>
                  <input type='text' className='form-control ml-2' id='email' name='email' required='required' />
                </div>
              </div>
              <div className='form-group form-row'>
                <div className='form-group'>
                  <label className='ml-2' htmlFor='pass'>Contraseña</label>
                  <input type='password' className='form-control ml-2' id='pass' name='pass' required='required' />
                </div>
                <div className='form-group '>
                  <button className='btn btn-primary ml-3 lol' type='submit'>Agregar Usuario</button>
                </div>
              </div>
            </form>
          </div>
          <div className='col-md-6 border'>
            <form onSubmit={this.handlePass}>
              <div className='form-group form-row pt-3'>
                <h3>Modificar Usuario</h3>
              </div>
              <div className='form-group form-row'>
                <div className='form-group'>
                  <label htmlFor='key'>Usuario a modificar</label>
                  <select name='key' className='form-control  ml-2' required='required'>
                    {this.state.users.map(({ username }) => <option key={username} value={username}>{username}</option>)}
                  </select>
                </div>
                <div className='form-group'>
                  <label className='ml-2' htmlFor='pass'>Nuevo nombre de ususario</label>
                  <input type='password' className='form-control ml-2' name='username' />
                </div>
                <div className='form-group'>
                  <label className='ml-2' htmlFor='pass'>Nueva Contraseña</label>
                  <input type='password' className='form-control ml-2' name='pass' />
                </div>
                <div className='form-group'>
                  <label className='ml-2' htmlFor='pass'>Nuevo email</label>
                  <input type='email' className='form-control ml-2' name='email' />
                </div>
                <div className='form-group '>
                  <button className='btn btn-primary ml-3 lol' type='submit'>Guardar Cambios</button>
                </div>
              </div>

            </form>
          </div>
        </div>
        <hr />

        <div className='row'>
          <div className='col border'>
            <form onSubmit={this.handleRecover}>
              <div className='form-group form-row pt-3'>
                <h3>Recuperar Contraseña</h3>
              </div>
              <div className='form-group form-row'>
                <div className='form-group'>
                  <label htmlFor='user'>Usuario</label>
                  <select name='user' className='form-control  ml-2' required='required'>
                    {this.state.users.map(({ username }) => <option key={username} value={username}>{username}</option>)}
                  </select>
                </div>
                <div className='form-group '>
                  <button className='btn btn-primary ml-3 lol' type='submit'>Enviar Email</button>
                </div>
              </div>
            </form>
          </div>
        </div>
        <hr />

        <div className='row'>
          <div className='col py-2' style={{ fontSize: '20px', captionSide: 'top', color: 'white', textAlign: 'center', backgroundColor: '#0562a6' }}>
            Accesos de usuarios
          </div>

        </div>

        <div className='row'>
          <div className='col overflow-auto px-0' style={{ height: '550px' }}>
            <table className='table table-striped border'>

              <thead>
                <tr>
                  <th scope='col'>Nombre usuario</th>
                  <th scope='col'>Activo</th>
                  {this.state.paths.map(p => <th key={p.url} scope='col'>{['Facturación'].includes(p.tipo) && `${p.tipo} - `}{p.name}</th>)}
                </tr>
              </thead>

              <tbody>
                {this.state.users.map(({ username, urls, enabled }) => (
                  <tr key={username}>
                    <td style={{ textAlign: 'center', verticalAlign: 'middle' }}>{username}</td>
                    <td style={{ textAlign: 'center', verticalAlign: 'middle' }}><input type='checkbox' defaultChecked={enabled} onChange={e => this.handleDisable(e.target.checked, username)} /></td>
                    {this.state.paths.map(p => <td key={p.url} style={{ textAlign: 'center', verticalAlign: 'middle' }}><input type='checkbox' defaultChecked={urls.map(u => u.url).includes(p.url)} onChange={e => this.handleSite(e.target.checked, username, p.url)} /></td>)}
                  </tr>
                ))}
              </tbody>

            </table>
          </div>

        </div>
      </>
    )
  }
}
