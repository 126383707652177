import { jsPDF as JsPDF } from 'jspdf'
import html2canvas from 'html2canvas'

export default function parse (number) {
  if (number === undefined || number === null) return null
  else return parseFloat(number.toFixed(2)).toLocaleString()
}

export function downloadCSV (title, tableEle, separator = ',') {
  const csvRows = []
  // only get direct children of the table in question (thead, tbody)
  Array.from(tableEle.children).forEach(function (node) {
    // using scope to only get direct tr of node
    node.querySelectorAll(':scope > tr').forEach(function (tr) {
      const csvLine = []
      // again scope to only get direct children
      tr.querySelectorAll(':scope > td, :scope > th').forEach(function (td) {
        // clone as to not remove anything from original
        const copytd = td.cloneNode(true)
        let data
        if (copytd.dataset.val) data = copytd.dataset.val.replace(/(\r\n|\n|\r)/gm, '')
        else {
          Array.from(copytd.children).forEach(function (remove) {
            // remove nested elements before getting text
            remove.parentNode.removeChild(remove)
          })
          data = copytd.textContent.replace(/(\r\n|\n|\r)/gm, '')
        }
        data = data.replace(/(\s\s)/gm, ' ').replace(/"/g, '""')
        csvLine.push('"' + data + '"')
      })
      csvRows.push(csvLine.join(separator))
    })
  })
  const a = document.createElement('a')
  a.style = 'display: none; visibility: hidden' // safari needs visibility hidden
  a.href = 'data:text/csv;charset=utf-8,' + encodeURIComponent(csvRows.join('\n'))
  a.download = `${title}.csv`
  document.body.appendChild(a)
  a.click()
  a.remove()
}

export function downloadPDF (title, element) {
  html2canvas(element).then(function (canvas) {
    const divImage = canvas.toDataURL('image/png')
    const pdf = new JsPDF({ orientation: 'landscape' })

    pdf.addImage(divImage, 'PNG', 0, 0)
    pdf.save(`${title}.pdf`)
  })
}
