import { Component } from 'react'
import { getAPI } from '../FormUtil'
import moment from 'moment'

import { Col, Row } from 'reactstrap'

// Tabla
export default class TablaDeInformacion extends Component {
  state = { ordenes: [] }

  componentDidMount () {
    getAPI('inventario/orden/final').then(res => {
      this.setState({ ordenes: res.data })
    })
    this.props.appState({ header: '', state: 'ready' })
  }

  render () {
    return (
      <div className={this.props.showRender ? '' : 'hideRender'}>
        <Row className='mt-2 justify-content-center'><h2>Órdenes Finalizadas</h2></Row>
        {this.state.ordenes.map((row, i) => <>{i === 0 ? '' : <hr />}<Orden key={row.id} row={row} /></>)}
      </div>

    )
  }
};

class Orden extends Component {
  state={ items: [], modal: false, log: [], devolucion: [] }
  componentDidMount () {
    getAPI(`inventario/orden/${this.props.row.id}`).then(res => {
      this.setState({ items: res.data })
    })
    getAPI(`inventario/orden/log/${this.props.row.id}`).then(res => {
      this.setState({ log: res.data })
    })
    getAPI(`inventario/devolucion/${this.props.row.id}`).then(res => {
      this.setState({ devolucion: res.data })
    })
  }

  render () {
    return (
      <Row>
        <Col md={12}>
          <legend>Órden #{this.props.row.id}</legend>
          <caption style={{ width: '100%' }}>Transportista: {this.props.row.transportista} / Marchamo: {this.props.row.marchamo}</caption>
          <Row>
            <Col md={6} className='table-responsive'>
              <table className='table table-striped'>
                <thead>
                  <tr>
                    <th scope='col'>Código</th>
                    <th scope='col'>Nombre</th>
                    <th scope='col'>Cantidad</th>
                  </tr>
                </thead>
                <tbody>
                  {this.state.items.map(row => (
                    <tr key={row.item}>
                      <td>{row.item}</td>
                      <td>{row.nombre}</td>
                      <td>{row.cantidad}</td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </Col>
            <Col md={6} className='table-responsive col-md-6'>
              <table className='table table-striped'>
                <thead>
                  <tr>
                    <th scope='col'>Estado</th>
                    <th scope='col'>Dia</th>
                    <th scope='col'>Hora</th>
                    <th scope='col'>Usuario</th>
                  </tr>
                </thead>
                <tbody>
                  {this.state.log.sort((a, b) => moment(a.tiempo).valueOf() - moment(b.tiempo).valueOf()).map(row => {
                    const date = moment(row.tiempo)
                    return (
                      <tr key={row.estado}>
                        <td>{row.estado}</td>
                        <td>{date.format('D - MMM - YYYY')}</td>
                        <td>{date.format('h:mm A')}</td>
                        <td>{row.usuario}</td>
                      </tr>
                    )
                  })}
                </tbody>
              </table>
            </Col>
          </Row>
          {this.state.devolucion.length > 0 ? (
            <Row>
              <Col md={6} className='table-responsive'>
                <caption>Devolucion</caption>
                <table className='table table-striped'>

                  <thead>
                    <tr>
                      <th scope='col'>Código</th>
                      <th scope='col'>Nombre</th>
                      <th scope='col'>Cantidad</th>
                    </tr>
                  </thead>
                  <tbody>
                    {this.state.devolucion.map(row => (
                      <tr key={row.item}>
                        <td>{row.item}</td>
                        <td>{row.nombre}</td>
                        <td>{row.cantidad}</td>
                      </tr>
                    ))}
                    <tr>Observacion: {this.state.devolucion[0].observacion}</tr>
                  </tbody>
                </table>
              </Col>
            </Row>
          ) : null}
        </Col>
      </Row>
    )
  }
}
