import { useState, useRef } from 'react'
import axios from 'axios'
import { toast } from 'react-toastify'
import useAxios from 'axios-hooks'
import classname from 'classnames'

require('dotenv').config({ path: '../../../.env' })

const capitalize = s => {
  if (typeof s !== 'string') return ''
  return s.charAt(0).toUpperCase() + s.slice(1)
}

function Lavadora (props) {
  const [show, setShow] = useState(true)

  return (
    <div className={classname('Lavadora', show ? 'col-md-4' : 'col-md-1')}>
      <div className='numeroLavadora row'>
        <h3 className={classname({ open: show })} onClick={() => setShow(!show)}>{props.name}</h3>
      </div>
      <div className='cuerpoLavadora row'>
        {show ? (
          <div className='contenedor-botones-lavadora'>
            {
              <div>
                {Object.keys(props.quimicos).map(quimico =>
                  <TagInput key={quimico} quimico={quimico} lavadora={props.name} default={props.quimicos[quimico].data.value} refetch={props.refetch} />
                )}
              </div>
            }
          </div>
        ) : (
          <div className='contenedor-botones-lavadora'><h3 className='verticaltext'>{props.name}</h3></div>
        )}
      </div>
    </div>)
}

function TagInput (props) {
  const [disabled, setDisabled] = useState(false)
  const valueEl = useRef(null)

  let timeout = null

  function sendTag (ev) {
    clearTimeout(timeout)

    timeout = setTimeout((quimico, lavadora) => {
      setDisabled(true)

      axios.post('/api/post/tag', { value: valueEl.current.value, path: `/San Juan/Tarado/${lavadora}/${quimico}` }).then(res => {
        setDisabled(false)
      }).catch(err => {
        console.log(err.response.data)
        setDisabled(false)
        valueEl.current.value = props.default

        toast.error(`Fallo al actualizar ${JSON.parse(err.config.data).path}`, {
          position: toast.POSITION.TOP_LEFT
        }, { autoClose: 5000 })
      })
    }, 1000, props.quimico, props.lavadora)
  }

  return (
    <div key={props.quimico} className='row w-100 px-5 justify-content-center ingresarQuimicos'>
      <div className='col-4'>
        <p>{capitalize(props.quimico)}: </p>
      </div>
      <div className='col-auto'>
        <input style={{ width: '50px' }} ref={valueEl} type='text' size='4' disabled={disabled} onChange={sendTag} defaultValue={props.default} />
      </div>
    </div>
  )
}

export default function RowLavadoras () {
  const [{ data, loading, error }, refetch] = useAxios('/api/get/tarados')

  if (loading) return <p>Loading...</p>
  if (error) return <p>Error!</p>

  return (
    <div className='row'>
      {Object.keys(data).map(lavadora =>
        <Lavadora key={lavadora} name={lavadora} quimicos={data[lavadora].tags} refetch={refetch} />
      )}
    </div>
  )
}
