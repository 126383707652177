import axios from 'axios'
import { useState, useEffect } from 'react'
import { toast } from 'react-toastify'
import { A } from 'hookrouter'
import {
  Container, Row, Col, Navbar, NavbarToggler, Collapse, DropdownItem, UncontrolledDropdown, DropdownToggle, DropdownMenu,
  NavItem, NavLink, Nav
} from 'reactstrap'

function logout () {
  axios.post('/api/logout').then(function (response) {
    window.location.pathname = '/'
  })
    .catch(function (error) {
      console.log(error)
      // toastr['error']('Error al actualizar registros')
      toast.error('Error al actualizar registros', {
        position: toast.POSITION.TOP_RIGHT
      }, { autoClose: false })
    })
}

export default function NavSide (props) {
  const [items, setItems] = useState([])
  const [isOpen, setIsOpen] = useState(false)

  const toggle = () => setIsOpen(!isOpen)

  useEffect(() => {
    axios.get('/api/itemList').then(response => setItems(response.data))
  }, [])

  return (
    <header>
      <div className='banner-bg py-3'>
        <Container className='mt-0'>
          <Row>
            <Col className='py-3 ml-auto' xs='auto'>
              <img alt='vsmart_logo' src='/img/vsmart.png' height='60px' />
            </Col>
          </Row>
        </Container>
      </div>

      <Navbar className='navbar-expand-md navbar navbar-dark bg-primary sticky-top'>
        {props.signedIn && (
          <>
            <NavbarToggler onClick={toggle} />
            <Collapse isOpen={isOpen} navbar>
              <Nav navbar className='mx-auto' id='navbarAdd'>
                <NavItem className='px-md-2'>
                  <NavLink tag={A} href='/'>Home</NavLink>
                </NavItem>
                {items && Object.entries(items).map(([name, items], i) => {
                  return (
                    <UncontrolledDropdown key={i} nav inNavbar className='px-md-2'>
                      <DropdownToggle nav caret>
                        {name}
                      </DropdownToggle>
                      <DropdownMenu className='mx-md-2'>
                        {items.map(({ name, url }, i) => <DropdownItem tag={A} key={i} className='dropdown-item' href={url}>{name}</DropdownItem>)}
                      </DropdownMenu>
                    </UncontrolledDropdown>
                  )
                })}
                <NavItem className='px-md-2'>
                  <NavLink href='#' onClick={logout}>Cerrar Sesión</NavLink>
                </NavItem>
              </Nav>
            </Collapse>
          </>
        )}
      </Navbar>
    </header>
  )
}
