import React, { useState, useEffect } from 'react'
import { DateTime } from 'luxon'
import axios, { CancelToken } from 'axios'
import { Col, Row } from 'reactstrap'

import { downloadCSV, downloadPDF } from '../utils'

import DatePicker from '../componentes/DatePicker'
import State from '../componentes/States'
import Button from 'reactstrap/lib/Button'

const combine = {
  Cobijas: 'Leve',
  Sangrante: 'Muy_Sucia',
  Sabanas_carit: 'Muy_Sucia',
  Operaciones: 'Muy_Sucia'
}

export default function Content () {
  const [dates, setDates] = useState()
  const [state, setState] = useState('')
  const [data, setData] = useState()

  useEffect(() => {
    if (!dates) return
    let cancel

    setState('loading')

    axios.get('/api/get/kilogramos/total/total', {
      params: { dates }
    }, { cancelToken: new CancelToken(function executor (c) { cancel = c }) }).then(res => {
      setState('')
      setData(res.data)
    }).catch(err => {
      if (!axios.isCancel(err)) {
        console.log(err)
        setData(undefined)
        setState('error')
      }
    })

    return () => {
      if (cancel) cancel()
    }
  }, [dates])

  function printPDF () {
    downloadPDF(`Reporte Facturación ${dates.join(' - ')}`, document.getElementById('ReporteKilogramos'))
  }

  function printCSV () {
    downloadCSV(`Reporte Facturación ${dates.join(' - ')}`, document.getElementById('ReporteKilogramos'))
  }

  return (
    <>
      <Row className='mt-2 justify-content-center'><h2>Reporte Facturación</h2></Row>
      <Row className='mt-2'>
        <Col md='auto' className='d-flex justify-content-start'>
          <DatePicker simple handleDate={strings => setDates(strings.map(e => DateTime.fromJSDate(e).toFormat('yyyy-MM-dd')))} id='datepick' />
        </Col>
        <Col className='my-4 my-md-0 mt-md-auto d-flex justify-content-center justify-content-md-end'>
          <Button onClick={printPDF} style={{ height: '35px', backgroundColor: '#4aa0d1' }} className='mx-2 mt-auto'><i className='fa fa-download px-1' aria-hidden='true' />Exportar PDF</Button>
          <Button onClick={printCSV} style={{ height: '35px', backgroundColor: '#4aa0d1' }} className='mx-2 mt-auto'><i className='fa fa-download px-1' aria-hidden='true' />Exportar CSV</Button>
        </Col>
      </Row>
      <Row className='mt-4 justify-content-center'>
        <Col md='auto'>
          <span style={{ color: '#4158d4' }} className='mr-1'>Muy Sucia </span><span> = Muy Sucia + Sangrante +  Sábanas Carit +  Operaciones</span>
        </Col>
        <Col md='auto'>
          <span style={{ color: '#4158d4' }}>Leve </span><span>= Leve + Cobijas</span>
        </Col>
      </Row>
      <Row className='mt-4'>
        <Col md={12}>
          <State state={state} />
          {data && <Reporte data={data.results} dates={dates} />}
        </Col>
      </Row>
    </>
  )
}

function Reporte (props) {
  const lavs = Object.keys(props.data).sort()
  const progs = new Set()

  const result = {}
  const resultProg = {}

  lavs.forEach((lav, lavIndex) => {
    Object.keys(props.data[lav]).sort().forEach(progRaw => {
      const prog = combine[progRaw] ? combine[progRaw] : progRaw

      progs.add(prog)
      if (!result[lav]) result[lav] = { [prog]: 0 }
      if (!result[lav][prog]) result[lav][prog] = 0
      if (!resultProg[prog]) resultProg[prog] = 0

      result[lav][prog] += props.data[lav][progRaw]
      resultProg[prog] += props.data[lav][progRaw]
    })
  })

  return (
    <Row>
      <Col>
        <div className='table-responsive'>
          <table id='ReporteKilogramos' className='table table-striped'>
            <thead>
              <tr>
                <th colSpan={2 + [...progs].length} scope='row'>Reporte de facturación {props.dates.join(' - ')}</th>
              </tr>
              <tr>
                <th scope='col'>Lavadora</th>
                {
                  [...progs].map(prog => <th scope='col' key={prog}>{prog}</th>)
                }
                <th scope='col'>Total</th>
              </tr>
            </thead>
            <tbody>
              {Object.keys(result).map(lav =>
                <tr key={lav}>
                  <td>{lav}</td>
                  {[...progs].map(prog => <td key={prog}>{result[lav][prog]}</td>)}
                  <td>{Object.values(result[lav]).reduce((a, b) => a + b)}</td>
                </tr>
              )}

              <tr style={{ backgroundColor: 'white', fontWeight: 'bold' }}>
                <td>TOTAL</td>
                {[...progs].map(prog => <td key={prog}>{resultProg[prog]}</td>)}
                <td>{Object.values(resultProg).reduce((a, b) => a + b)}</td>
              </tr>
            </tbody>
          </table>
        </div>
      </Col>
    </Row>
  )
}
