import { Component, createRef } from 'react'
import { postAPI, getAPI } from '../FormUtil'
import { toast } from 'react-toastify'

import { Col, Row, Button, Form, FormGroup, Label, Input } from 'reactstrap'

// Tabla
export default class TablaDeInformacion extends Component {
  state = { sitios: [], items: [] }

  componentDidMount () {
    this.props.appState({ header: '', state: 'ready' })
    this.triggerUpdate()
  }

  triggerUpdate = () => {
    getAPI('inventario/sitio')
      .then(res => this.setState({ sitios: res.data.map(e => e.nombre) }))

    getAPI('inventario/item')
      .then(res => this.setState({ items: res.data }))
  }

  render () {
    return (
      <div className={this.props.showRender ? '' : 'hideRender'}>
        <Row className='mt-2 justify-content-center'><h2>Configuración</h2></Row>
        <PostSitio triggerUpdate={this.triggerUpdate} />
        <SitioList triggerUpdate={this.triggerUpdate} sitios={this.state.sitios} />
        <br />
        <PostItem triggerUpdate={this.triggerUpdate} />
        <ItemList triggerUpdate={this.triggerUpdate} items={this.state.items} />
      </div>

    )
  }
};

class PostSitio extends Component {
  nombre = createRef()

  handleSubmit = event => {
    postAPI('inventario/sitio', { nombre: this.nombre.current.value })
      .then(() => {
        toast.success(`Inventorio "${this.nombre.current.value}" creado`)
        event.target.reset()
        this.props.triggerUpdate()
      })
    event.persist()
    event.preventDefault()
  }

  render () {
    return (
      <Row>
        <Col md={12}>
          <Form onSubmit={this.handleSubmit}>
            <legend>Agregar Sitio</legend>
            <Row>
              <Col md={4}>
                <FormGroup>
                  <Input type='text' innerRef={this.nombre} name='nombre' placeholder='Nombre' required />
                </FormGroup>
              </Col>

              <FormGroup className='mt-auto'>
                <Button color='primary' type='submit'>Confirmar</Button>
              </FormGroup>
            </Row>
          </Form>
        </Col>
      </Row>
    )
  }
}

class PostItem extends Component {
  id = createRef()
  nombre = createRef()

  handleSubmit = event => {
    postAPI('inventario/item', { id: this.id.current.value, nombre: this.nombre.current.value }).then(() => {
      toast.success(`Artículo "${this.nombre.current.value}" agregado`)
      event.target.reset()
      this.props.triggerUpdate()
    })
    event.persist()
    event.preventDefault()
  }

  render () {
    return (
      <Row>
        <Col md={12}>
          <Form onSubmit={this.handleSubmit}>
            <legend>Agregar Artículo</legend>
            <Row>
              <Col md={2}>
                <FormGroup>
                  <Label for='nombre'>Código</Label>
                  <Input type='number' innerRef={this.id} name='id' required />
                </FormGroup>
              </Col>
              <Col md={4}>
                <FormGroup>
                  <Label for='nombre'>Nombre</Label>
                  <Input type='text' innerRef={this.nombre} name='nombre' required />
                </FormGroup>
              </Col>

              <FormGroup className='mt-auto'>
                <Button color='primary' type='submit'>Confirmar</Button>
              </FormGroup>
            </Row>

          </Form>
        </Col>
      </Row>
    )
  }
}

class SitioList extends Component {
  render () {
    return (
      <Row>
        <Col>
          Sitios: {this.props.sitios.join(' - ')}
        </Col>
      </Row>
    )
  }
}

class ItemList extends Component {
  render () {
    return (
      <Row>
        <Col md={12}>
          <div className='table-responsive'>
            <table className='table table-striped' style={{ width: 'auto' }}>
              <thead>
                <tr>
                  <th scope='col'>Código</th>
                  <th scope='col'>Nombre</th>
                </tr>
              </thead>
              <tbody>
                {this.props.items.map(item => <tr key={item.id}><td>{item.id}</td><td>{item.nombre}</td></tr>)}
              </tbody>
            </table>
          </div>

        </Col>
      </Row>
    )
  }
}
